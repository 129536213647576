import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { getRequest } from '@/lib/api';

const initialState = {
  estimations: {
    purchasedPrice: 0,
    sellingPrice: 0,
    stock: 0,
  },
  status: 'idle',
  error: undefined,
};

export const getEstimations = createAsyncThunk<any, { query: string; userId?: string; token?: string }, {}>(
  'users/fetchEstimations',
  async ({ query, userId, token }, { rejectWithValue }) => {
    const response = await getRequest({ endpoint: `/users/${userId}/estimations?${query}`, token });
    if (!response.data) {
      return rejectWithValue(response?.error?.message);
    }
    return response.data;
  }
);

const getEstimationsSlice = createSlice({
  name: 'fetchEstimations',
  initialState,
  reducers: {
    cleanupInventory: (state) => {
      state.estimations = {
        purchasedPrice: 0,
        sellingPrice: 0,
        stock: 0,
      };
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getEstimations.pending, (state) => {
      state.status = 'pending';
    });
    builder.addCase(getEstimations.fulfilled, (state, action) => {
      state.status = 'success';
      state.estimations = action.payload?.data?.estimations || action.payload?.data;
    });
    builder.addCase(getEstimations.rejected, (state, action) => {
      state.status = 'failed';
      state.error = action.payload as any;
    });
  },
});

export const { cleanupInventory } = getEstimationsSlice.actions;
export default getEstimationsSlice.reducer;
